import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as nutils from 'app/nutils';
import * as AppDef from 'app/app-define';

@Injectable({
  providedIn: 'root'
})
export class CognitoSettingsService {

  constructor() {
  }

  // 顧客ログイン設定を取得
  getEnduserLoginConfig(serviceId: string, s3only: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = nutils.apiUrl(AppDef.MSname.SERVICE, `/enduser-login-config/${serviceId}`);

      fetch(url).then(async res => {
        const json: { error_code, error_message, end_user_login_config } = await res.json();
        const end_user_login_config = JSON.parse(json.end_user_login_config);

        if (s3only) {
          resolve(json);
          return;
        }
        const amplifySettings = environment.amplify;
        amplifySettings.Auth.userPoolId = end_user_login_config.userPoolId;
        amplifySettings.Auth.userPoolWebClientId = end_user_login_config.userPoolWebClientId;
        switch (end_user_login_config.storage) {
          case 'localStorage':
            amplifySettings.Auth.storage = localStorage;
            break;
          case 'sessionStorage':
            amplifySettings.Auth.storage = sessionStorage;
            break;
          default:
            amplifySettings.Auth.storage = sessionStorage;
            break;
        }
        resolve(amplifySettings);
      }, err => {
        reject(err);
      });
    });
  }
}
