import { HttpClient, HttpErrorResponse, HttpParameterCodec, HttpUrlEncodingCodec } from '@angular/common/http';
import { defer } from 'rxjs';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TestBed } from '@angular/core/testing';
import { ToastrModule } from 'ngx-toastr';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';

import { MsLocalConf, MsApiPathConf } from 'app/ms-local-conf';
import { ApplicationError } from 'app/shared/error/application-error';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

export function isErrorResponse(response: any): boolean {
  if (response.errorCode && (response.errorCode as string).length > 0) {
    return true;
  }

  return false;
}

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function commonConfigureTestingModule() {
  TestBed.configureTestingModule({
    imports: [
      ToastrModule.forRoot({
        timeOut: 2000,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
      }),
      LoggerModule.forRoot({
        serverLoggingUrl: null, // environment.serverLoggingUrl,
        level: NgxLoggerLevel.DEBUG,
        serverLogLevel: NgxLoggerLevel.DEBUG,
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        }
      }),
      //      ShareModule,
    ],
  });
}

/** Create async observable that emits-once and completes
 *  after a JS engine turn */
export function asyncData<T>(data: T) {
  return defer(() => Promise.resolve(data));
}

export function promiseData<T>(data: T) {
  return Promise.resolve(data);
}

/** Create async observable error that errors
 *  after a JS engine turn */
export function asyncError<T>(errorObject: any) {
  return defer(() => Promise.reject(errorObject));
}

export function expectEqualApplicationError(
  err: Error | any, errorCode: string
): boolean {

  if (err.code && err.code === errorCode) {
    return true;
  } else if (err.errorCode && err.errorCode === errorCode) {
    return true;
  }

  return false;
}

export function toItemIndexes<T>(a: T[]) {
  return a.map((item, index) => ({ item, index }));
}

export function toItemIndexes2<T>(a: T[]) {
  return a.map((item2, index2) => ({ item2, index2 }));
}

export class CustomParameterEncoding extends HttpUrlEncodingCodec implements HttpParameterCodec {
  // +文字をエンコードしない。
  encodeValue(v: string): string {
    return encodeURIComponent(v)
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/gi, '$')
      .replace(/%2C/gi, ',')
      .replace(/%3B/gi, ';')
      .replace(/%3D/gi, '=')
      .replace(/%3F/gi, '?')
      .replace(/%2F/gi, '/');
  }
}

export function apiUrl(cname: string, path: string): string {
  let apiurl = '';

  const place = MsLocalConf[cname];
  let apiPath = MsApiPathConf[cname];

  if (environment.env === 'local') {

    if (place === undefined || place === 'aws') {
      apiurl = environment.apiUrlBaseAws;
    } else if (place === 'local') {
      apiPath = '';
      apiurl = environment.apiUrlBase;
      apiPath = ''; // local の場合は lambda authorizer は無効化する
    } else if (place === 'lambda-aws') {
      apiurl = environment.apiLambdaUrlBase;
    } else if (place === 'service01-lambda-aws') {
      apiurl = environment.apiLambdaUrlBase;
    } else {
      throw ApplicationError.occurredCodingError('no config ms-local-conf value');
    }

    apiurl += apiPath + path;
  } else {
    if (place === 'lambda-aws') {
      apiurl = environment.apiLambdaUrlBase;
    } else if (place === 'service01-lambda-aws') {
      apiurl = environment.apiLambdaUrlBase;
    } else {
      apiurl = environment.apiUrlBase;
    }

    apiurl += apiPath + path;
  }

  return apiurl;
}


// --------新エラー制御 START -------------------------------------------------------------------------------
export function checkServerError(result: any) {
  if (result && result.errorCode) {
    let emsg = '';
    if (result.errorMessage) {
      emsg = result.errorMessage;
    }

    throw handleErrorThrown(new ApplicationError(
      {
        serverErrorCode: result.errorCode,
        msg: emsg,
      })
    );
  }
}

export function handleErrorThrown(err: Error): Error {

  if (err instanceof ApplicationError) {
    return err;
  } else if (err instanceof HttpErrorResponse) {
    //    const aext = new ApplicationErrorExt('', '');
    // エラーコードの1桁目、'R' リトライ可能であれば、retryオプションをtrueにする。
    let retry = false;
    let type = '';
    if (err.error && err.error.errorCode && err.error.errorCode.length > 0) {
      type = err.error.errorCode as string;
      if (type.substr(0, 1) === 'R') {
        retry = true;
      }
    }
    else if (err.status === 403) {
      return new ApplicationError({
        serverErrorCode: '403',
        msg: '操作を実行する権限がありません。',
        retry: true,
        status: err.status,
        statusText: err.statusText,
      });
    }

    const aext = new ApplicationError({
      serverErrorCode: err.error.errorCode,
      msg: err.error.Message,
      retry: retry,
      status: err.status,
      statusText: err.statusText,
    });

    return aext;
  }

  let c1002Msg = '既存エラーをハンドリングします';
  if (err.message) {
    c1002Msg = err.message;
  }

  const insteadErr = ApplicationError.occurredError('A1002', c1002Msg);
  return insteadErr;
}

// --------新エラー制御 END -------------------------------------------------------------------------------

// -------- ページ離脱制御 START -------------------------------------------------------------------------------
export interface OnBeforeunload {
  shouldConfirmOnBeforeunload: () => boolean;
}

@Injectable()
export class BeforeunloadGuard implements CanDeactivate<OnBeforeunload> {
  canDeactivate(component: OnBeforeunload) {
    if (component.shouldConfirmOnBeforeunload()) {
      const msg = 'このページを離れてもよろしいですか？\n変更が保存されない可能性があります。';
      return confirm(msg);
    }

    return true;
  }
}

// -------- ページ離脱制御 END -------------------------------------------------------------------------------
