export namespace MSname {
  export const VIDEO = 'video';
  export const DEVICE = 'device';
  export const SERVICE = 'service';
  export const SERVICE_ACCESS = 'service-access';
  export const ENDUSER = 'enduser';
  export const CAMERA_ENDUSER = 'camera-enduser';
  export const COGNITO_CUSTOMIZE = 'cognito-customize';
  export const SERVICE01_COGNITO_CUSTOMIZE = 'service01-cognito-customize';
}

export const CAMERA_NUMS_IN_VIEWER = 9;
export const VIEWER_NUMS = 10;


