import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
// import { ApplicationError } from './application-error';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationError } from './application-error';
import { AppErrorDataService } from './app-error-data.service';
import { NgxSpinnerService } from 'ngx-spinner';

// 共通モジュール設定

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends ErrorHandler {
  constructor(
    private logger: NGXLogger,
    private injector: Injector,
    private zone: NgZone,
    private msgadm: TranslateService,
    // スピナー
    private spinner: NgxSpinnerService,
    private appErrorDataService: AppErrorDataService,
  ) {
    super();


  }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  //    private router: Router;
  private errors: ApplicationError[] = [];

  handleError(error: any): void {
    // スピナーが表示されていたら、消去しておく。
    //    this.spinner.hide();


    //        router2.navigate(['/carrier/tc-driver-deck-management', { relativeTo: router2.}]);
    //        router2.navigateByUrl('/tc-driver-deck-management');

    if (error instanceof ApplicationError) {
      this.addError(error);

    } else {
      if (error.rejection instanceof ApplicationError) {
        //        router2.navigate(['/portal/tp-login']);
        this.addError(error.rejection);

      } else {
        super.handleError(error);
      }
    }
  }

  addError(error: ApplicationError) {
    this.errors.push(error);

    if (error.canRetry) {
      this.logger.warn('************************************');
      this.logger.warn('*** Caught an warning exception ***');
      this.logger.error('*** --- ERROR CODE ---');
      this.logger.error('code:', error.code);
      this.logger.warn('*** --- ERROR MESSAGE ---');
      this.logger.warn('message:', error.message);
      this.logger.warn('*** --- ERROR NAME ---');
      this.logger.warn('name:', error.name);
      this.logger.warn('*** --- STACK TRACE ---');
      this.logger.warn('stack:', error.stack);
      this.logger.warn('************************************');
    } else {
      this.logger.error('************************************');
      this.logger.error('*** Caught an error exception ***');
      this.logger.error('*** --- ERROR CODE ---');
      this.logger.error('code:', error.code);
      this.logger.error('*** --- ERROR MESSAGE ---');
      this.logger.error('message:', error.message);
      this.logger.error('*** --- ERROR NAME ---');
      this.logger.error('name:', error.name);
      this.logger.error('*** --- STACK TRACE ---');
      this.logger.error('stack:', error.stack);
      this.logger.error('************************************');
    }


    const appError = error as ApplicationError;
    if (appError.canRetry) {
      this.zone.run(() => {
        let emsg = '';
        if (appError.message) {
          emsg = appError.message;
        }
        else {
          emsg = this.msgadm.instant('SYSERR-C01');
        }

        this.toastr.error(
          emsg,
          this.msgadm.instant('SYSERR-TITLE'),
          {
            closeButton: true,
            disableTimeOut: true,
          }
        );
      });


      // メッセージID 省略可能 デフォルトあるように
      // 行き先 省略可能 省略したら再試行モード。
      // 行き先には、システムエラーも用意する。

      return;
    } else {
      const router2 = this.injector.get(Router);
      /* トースト表示はやめる
    this.zone.run(() => {

      this.toastr.error(
        this.msgadm.instant('SYSERR-C02'),
        this.msgadm.instant('SYSERR-TITLE'),
        {
          closeButton: true,
          disableTimeOut: true,
        }
      );

    });
      */

      if (appError.nextLocation) {
        // サービスにエラーデータを設定。
        this.appErrorDataService.updateError(appError);

        // 画面遷移を指示。
        this.zone.run(() => {
          router2.navigate([appError.nextLocation]);
        });
      }
    }
  }
}
