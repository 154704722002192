import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NavbarService {
  private titleSb = new Subject<string>();

  // 読み取り時に使用
  get title$() {
    return this.titleSb.asObservable();
  }

  updatetitle(str: string) {
    this.titleSb.next(str);
  }
}
