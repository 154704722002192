import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../auth/auth.service';
import { ServiceLabels } from '../models/services';
import { ServicesService } from '../services/services.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  constructor(
    private auth: AuthService,
    private logger: NGXLogger,
    private servicesService: ServicesService,
    // サニタイザー
    private sanitizer: DomSanitizer,
  ) {
  }
  //Variables
  currentDate: Date = new Date();
  loginImageUrl: string;
  copyright: SafeHtml;
  serviceLabels: ServiceLabels = undefined;

  async ngOnInit() {
    this.logger.info('FooterComponent.ngOnInit start');
    this.serviceLabels = this.servicesService.getServiceLabelsValue()
    if (this.serviceLabels == undefined) {
      const serviceId = this.auth.getServiceId()
      const serviceLabelsRes = await this.servicesService.getServiceLabels(serviceId).toPromise();
      this.logger.debug("serviceLabelsRes: ")
      this.logger.debug(serviceLabelsRes)
      this.serviceLabels = serviceLabelsRes.service_labels
    }
    this.loginImageUrl = this.serviceLabels.end_user_login_image_url
    this.logger.debug("loginImageUrl: " + this.loginImageUrl)
    this.copyright = this.sanitizer.bypassSecurityTrustHtml(this.serviceLabels.copyright)
    this.logger.debug("copyright: " + this.copyright)
  }
}
