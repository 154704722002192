import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import Amplify, { Auth } from 'aws-amplify';
import { CognitoSettingsService } from './cognito-settings.service';
import { Subject } from 'rxjs';

@Injectable()
export class AuthService {
  auth: CognitoAuth;

  private loginStatusChangeSource = new Subject<boolean>();
  loginStatusChange$ = this.loginStatusChangeSource.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cognitoSettingsService: CognitoSettingsService
  ) {
    // const clientId = localStorage.getItem('client_id');
    // const poolId = localStorage.getItem('pool_id');
    // environment.openid_connect.ClientId = clientId;
    // environment.amplify.Auth.userPoolId = poolId;
    // environment.amplify.Auth.userPoolWebClientId = clientId;

    // this.auth = new CognitoAuth(environment.openid_connect);
  }

  // IDトークン取得
  public async getIdToken(): Promise<string> {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
  }

  // Cognito 属性取得
  // NOTE: カスタム属性を取得する場合は name に 'custom:' のプレフィックスをつける必要があります
  public async getAttribute(name: string = null): Promise<{} | string> {
    const user = await Auth.currentAuthenticatedUser();
    return new Promise(async (resolve, reject) => {
      try {
        const attributes = await Auth.userAttributes(user);
        if (name === null) {
          resolve(attributes);
        }
        attributes.forEach(data => {
          if (data.getName() === name) {
            resolve(data.getValue());
          }
        });
        reject();
      } catch (error) {
        console.error('auth.service getAttribute() error:', error);
        reject(error);
      }
    });
  }

  // Cognito 属性更新
  public async setAttribute(attributes: {}): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Auth.updateUserAttributes(user, attributes);
        resolve(res);
      } catch (error) {
        console.error('auth.service setAttribute() error:', error);
        reject(error);
      }
    });
  }

  // code がクエリーパラメータに含まれていたらCognitoトークンエンドポイントからトークンを取得
  isOAuthRedirect(): Promise<any> {
    return new Promise((resolve, reject) => {

      // クエリーパラメータの code の有無判定
      const params = this.activatedRoute.snapshot.queryParams;
      console.log('auth.service isOAuthRedirect() params.code:', params.code);
      if (!params.code) {
        resolve(false);
      }

      // ハンドラをセット
      this.auth.userhandler = {
        onSuccess: session => {
          resolve(session);

          this.isAuthenticated().then((isLogin) => {
            this.loginStatusChangeSource.next(isLogin);
          });
        },
        onFailure: error => {
          reject(error);
        }
      };

      // Cognito認証フロー - Authorization code grant の認証フローでトークンを取得しローカルストレージに保存
      this.auth.useCodeGrantFlow();
      this.auth.parseCognitoWebResponse(this.router.url);
    });
  }

  // トークンをリフレッシュ
  refreshTokens(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
          console.log('auth.service refreshTokens() err:', err, 'session:', session);
          if (err) {
            reject(err);
          }
          resolve(session);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  // ログイン済み判定 - トークンの妥当性チェックを行う
  isAuthenticated(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {

      // ローカルストレージからサービスID取得を試みる
      const serviceId = localStorage.getItem('serviceId');
      if (!serviceId) {
        resolve(false);
        return;
      }

      // ローカルストレージからローカルストレージの利用設定（stayLogin）を取得し反映
      const cognitoSettings = await this.cognitoSettingsService.getEnduserLoginConfig(serviceId);
      cognitoSettings.storage = localStorage.getItem('stayLogin') === 'true' ? localStorage : sessionStorage;
      Amplify.configure(cognitoSettings);

      Auth.currentAuthenticatedUser().then(result => {
        console.log('auth.service isAuthenticated() resolve(true)');
        resolve(true);
      }).catch(error => {
        console.error('auth.service isAuthenticated() reject(error) error:', error);
        resolve(false);
        //        reject(error);
      });
    });
  }

  // サインアップ
  async signUp(serviceId: string, formValues: any[]): Promise<any> {
    const params = {
      username: '',
      password: '',
      attributes: {
        'custom:service_id': serviceId
      }
    };
    formValues.forEach(data => {
      const key = Object.keys(data)[0];
      const val = data[key];
      console.log(key, val);
      switch (key) {
        case 'email':
          params.username = val;
          break;
        case 'password':
          params.password = val;
          break;
        case 'name':
          params.attributes['custom:name'] = val;
          break;
        case 'address1':
          params.attributes['custom:addr1'] = val;
          break;
        case 'address2':
          params.attributes['custom:addr2'] = val;
          break;
        case 'address3':
          params.attributes['custom:addr3'] = val;
          break;
        case 'receivenmail':
          params.attributes['custom:receivenmail'] = val;
          break;
        case 'std_name':
          params.attributes['name'] = val;
          break;
        default:
          break;
      }
    });
    console.log('auth.service signUp() params:', params);
    const cognitoSettings = await this.cognitoSettingsService.getEnduserLoginConfig(serviceId);

    // ローカルストレージからローカルストレージの利用設定（stayLogin）を取得し反映
    cognitoSettings.storage = localStorage.getItem('stayLogin') === 'true' ? localStorage : sessionStorage;

    Amplify.configure(cognitoSettings);
    return await Auth.signUp(params);
  }

  // サインアップ認証
  async signupConfirm(serviceId: string, email: string, codeParameter: string): Promise<any> {
    const cognitoSettings = await this.cognitoSettingsService.getEnduserLoginConfig(serviceId);

    // ローカルストレージからローカルストレージの利用設定（stayLogin）を取得し反映
    cognitoSettings.storage = localStorage.getItem('stayLogin') === 'true' ? localStorage : sessionStorage;

    Amplify.configure(cognitoSettings);
    return await Auth.confirmSignUp(email, codeParameter);
  }

  // サインイン
  async signIn(serviceId: string, formValues: { email: string, password: string, stayLogin: boolean }): Promise<any> {
    const cognitoSettings = await this.cognitoSettingsService.getEnduserLoginConfig(serviceId);

    // ローカルストレージにログイン状態を維持するの値を保持
    localStorage.setItem('stayLogin', formValues.stayLogin === true ? 'true' : 'false');

    // ローカルストレージにサービスIDを保持
    localStorage.setItem('serviceId', serviceId);

    // ログインフォームのログイン状態を維持するのチェックの状態でストレージ設定をオーバーライド
    cognitoSettings.storage = formValues.stayLogin === true ? localStorage : sessionStorage;

    Amplify.configure(cognitoSettings);
    return await Auth.signIn(formValues.email, formValues.password);
  }

  // サインアウト
  async signOut(serviceId: string, global: boolean = true): Promise<any> {
    // ステータス変更。
    this.loginStatusChangeSource.next(false);

    const cognitoSettings = await this.cognitoSettingsService.getEnduserLoginConfig(serviceId);

    // ローカルストレージからローカルストレージの利用設定（stayLogin）を取得し反映
    cognitoSettings.storage = localStorage.getItem('stayLogin') === 'true' ? localStorage : sessionStorage;

    Amplify.configure(cognitoSettings);
    const option = (global === true) ? { global: true } : {};
    return Auth.signOut(option);
  }

  getServiceId(): string {
    // ローカルストレージからサービスID取得を試みる
    const serviceId = localStorage.getItem('serviceId');
    return serviceId;
  }
}
