<div #wrapper class="wrapper"
  [ngClass]="{'nav-collapsed': iscollapsed, 'menu-collapsed': iscollapsed, 'sidebar-sm': isSidebar_sm, 'sidebar-lg': isSidebar_lg}"
  [dir]="options.direction">
  <div #appSidebar appSidebar id="app-sidebar" class="app-sidebar" (toggleHideSidebar)="toggleHideSidebar($event)"
    [ngClass]="{'hide-sidebar': hideSidebar}" data-active-color="white" [attr.data-background-color]="bgColor"
    [attr.data-image]="bgImage">
    <app-sidebar></app-sidebar>
    <div class="sidebar-background" #sidebarBgImage></div>
  </div>
  <app-navbar (toggleHideSidebar)="toggleHideSidebar($event)"></app-navbar>
  <div class="main-panel mt-2" id="main-panel">
    <div class="main-content" id="main-content">
      <div class="content-wrapper">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <!--
  <app-notification-sidebar></app-notification-sidebar>
  -->
</div>
