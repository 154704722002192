<!-- Sidebar Header starts -->
<div class="sidebar-header">
  <div class="logo clearfix">
    <a [routerLink]="['/']" class="logo-text float-left py-0">
      <div class="logo-img">
        <img [src]="logoUrl" />
      </div>
      <span class="text align-middle">{{serviceName}}</span>
    </a>
    <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle" href="javascript:;">
      <i #toggleIcon appSidebarToggle class="ft-toggle-right toggle-icon" data-toggle="expanded"></i>
    </a>
    <a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose" href="javascript:;">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content" [perfectScrollbar]>
  <div class="nav-container">
    <ul class="navigation" appSidebarList>
      <!-- First level menu -->
      <li appSidebarlink level="{{depth + 1}}" (toggleEmit)="handleToggle($event)" (click)="toggleSlideInOut()"
        [routePath]="menuItem.path" [classes]="menuItem.class" [title]="menuItem.title" [parent]=""
        *ngFor="let menuItem of menuItems"
        [ngClass]="{'has-sub': menuItem.class === 'has-sub' ? true: false, 'open': activeTitles.includes(menuItem.title) && !nav_collapsed_open ? true : false, 'nav-collapsed-open': nav_collapsed_open && activeTitles.includes(menuItem.title)}"
        [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'" [routerLinkActiveOptions]="{exact: true}">
        <!-- <a *ngIf="menuItem.isSwitch; else switchBlock">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title-switch">{{menuItem.title | translate }}</span>
            <ui-switch class="ui-switch" size="large" uncheckedLabel="" checkedLabel=""  (valueChange)="onValueChange($event, menuItem.switchKey)"></ui-switch>
            <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <ng-template #switchBlock> -->
        <a appSidebarAnchorToggle [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
          *ngIf="!menuItem.isExternalLink; else externalLinkBlock">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <ng-template #externalLinkBlock>
          <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.path !== '/viewer/multi'; else cameraViewerBlock">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
        </ng-template>
        <ng-template #cameraViewerBlock>
          <a (click)="goToLastWatchViewer()">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
        </ng-template>
        <!-- </ng-template> -->
        <!-- Second level menu -->
        <ul class="menu-content" *ngIf="menuItem.submenu.length > 0"
          [@slideInOut]="activeTitles.includes(menuItem.title) ? true : false">
          <li appSidebarlink level="{{depth + 2}}" (toggleEmit)="handleToggle($event)" [routePath]="menuSubItem.path"
            [classes]="menuSubItem.class" [title]="menuSubItem.title" [parent]="menuItem.title"
            *ngFor="let menuSubItem of menuItem.submenu; index as i"
            [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
            [ngClass]="{'has-sub': menuSubItem.class === 'has-sub' ? true: false, 'open': activeTitles.includes(menuSubItem.title) && !nav_collapsed_open ? true : false, 'nav-collapsed-open': nav_collapsed_open && activeTitles.includes(menuSubItem.title)}">
            <!-- <a *ngIf="menuSubItem.isSwitch; else switchSubBlock">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title-switch">{{menuSubItem.title | translate }}</span>
                <ui-switch class="ui-switch" size="large"  (valueChange)="onValueChange($event, menuSubItem.switchKey)"></ui-switch>
                <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <ng-template #switchSubBlock> -->
            <a appSidebarAnchorToggle [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]"
              *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <ng-template #externalSubLinkBlock>
              <a [href]="[menuSubItem.path]">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
            </ng-template>
            <!-- </ng-template> -->
            <!-- Third level menu -->
            <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0"
              [@slideInOut]="activeTitles.includes(menuSubItem.title) ? true : false">
              <li appSidebarlink level="{{depth + 3}}" [routePath]="menuSubsubItem.path"
                [classes]="menuSubsubItem.class" [title]="menuSubsubItem.title" [parent]="menuSubItem.title"
                *ngFor="let menuSubsubItem of menuSubItem.submenu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" [ngClass]="[menuSubsubItem.class]">
                <!-- <a *ngIf="menuSubsubItem.isSwitch; else switchSubsubBlock">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title-switch">{{menuSubsubItem.title | translate }}</span>
                    <ui-switch class="ui-switch" size="large" uncheckedLabel="" checkedLabel=""  (valueChange)="onValueChange($event, menuSubsubItem.switchKey)"></ui-switch>
                    <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #switchSubsubBlock> -->
                <a appSidebarAnchorToggle [routerLink]="[menuSubsubItem.path]"
                  *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                  <span *ngIf="menuSubsubItem.badge != '' "
                    [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a [href]="[menuSubsubItem.path]">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge != '' "
                      [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
                <!-- </ng-template> -->
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
