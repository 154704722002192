import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { PostViewerData, PutViewerData, ViewerListResponse, ViewerResponse } from '../models/viewer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as nutils from 'app/nutils';
import * as AppDef from 'app/app-define';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
  ) {
  }

  // これよりCall Microservice
  getViewerList(idToken: string): Observable<ViewerListResponse> {
    // this.logger.debug('viewer.getViewerList in');

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', idToken);
    const url = nutils.apiUrl(AppDef.MSname.CAMERA_ENDUSER, '/viewers');

    this.logger.debug('getViewerList url ', url);
    return this.http.get<ViewerListResponse>(url, { headers: httpHeaders })
    .pipe(
      tap((data) => {
          // this.logger.debug('getViewerList data: ', data);
          return data;
        },
        (error) => {
          // this.logger.warn('getViewerList error: ', error);
          throw nutils.handleErrorThrown(error);
        })
    );
  }

  getViewer(idToken: string, viewerId: string): Observable<ViewerResponse> {
    // this.logger.debug('viewer.getViewer in');

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', idToken);

    const url = nutils.apiUrl(AppDef.MSname.CAMERA_ENDUSER, '/viewers/' + viewerId);

    // this.logger.debug('getViewer url ', url);
    return this.http.get<ViewerResponse>(url, { headers: httpHeaders })
    .pipe(
      tap((data) => {
          // this.logger.debug('getViewer data: ', data);
          return data;
        },
        (error) => {
          // this.logger.warn('getViewer error: ', error);
          throw nutils.handleErrorThrown(error);
        })
    );
  }

  postViewer(idToken: string, viewerData: PostViewerData): Observable<ViewerListResponse> {
    // this.logger.debug('viewer.postViewer in');

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', idToken);

    const url = nutils.apiUrl(AppDef.MSname.CAMERA_ENDUSER, '/viewers');

    // this.logger.debug('postViewer url ', url);
    return this.http.post<ViewerListResponse>(url, viewerData, { headers: httpHeaders })
    .pipe(
      tap((data) => {
          // this.logger.debug('postViewer data: ', data);
          return data;
        },
        (error) => {
          // this.logger.warn('postViewer error: ', error);
          throw nutils.handleErrorThrown(error);
        })
    );
  }

  putViewer(idToken: string, viewerData: PutViewerData): Observable<ViewerListResponse> {
    // this.logger.debug('viewer.putViewer in');

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', idToken);

    const url = nutils.apiUrl(AppDef.MSname.CAMERA_ENDUSER, '/viewers');

    // this.logger.debug('putViewer url ', url);
    return this.http.put<ViewerListResponse>(url, viewerData, { headers: httpHeaders })
    .pipe(
      tap((data) => {
          // this.logger.debug('putViewer data: ', data);
          return data;
        },
        (error) => {
          // this.logger.warn('putViewer error: ', error);
          throw nutils.handleErrorThrown(error);
        })
    );
  }

  deleteViewer(idToken: string, viewerId: string): Observable<ViewerListResponse> {
    // this.logger.debug('viewer.deleteViewer in');

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', idToken);

    const url = nutils.apiUrl(AppDef.MSname.CAMERA_ENDUSER, '/viewers/' + viewerId);

    // this.logger.debug('deleteViewer url ', url);
    return this.http.delete<ViewerResponse>(url, { headers: httpHeaders })
    .pipe(
      tap((data) => {
          // this.logger.debug('deleteViewer data: ', data);
          return data;
        },
        (error) => {
          // this.logger.warn('deleteViewer error: ', error);
          throw nutils.handleErrorThrown(error);
        })
    );
  }
}
