export const MsLocalConf = {
  'device': 'aws',
  'video': 'aws',
  'service': 'aws',
  'enduser': 'aws',
  'camera-enduser': 'aws',
  'cognito-customize': 'lambda-aws',
  'service01-cognito-customize': 'service01-lambda-aws',
};

export const MsApiPathConf = {
  'device': '/eu',
  'video': '/eu-cam',
  'service': '/cc',
  'enduser': '/iotadm',
  'camera-enduser': '/eu-cam',
  'device-app': '',
  'cognito-customize': '',
  'service01-cognito-customize': '',
};
