import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { CameraEnduserConfigResponse, PutCameraEnduserConfigData } from '../models/camera-enduser-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as nutils from 'app/nutils';
import * as AppDef from 'app/app-define';

@Injectable({
  providedIn: 'root'
})
export class CameraEnduserConfigService {

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
  ) {
  }

  // これよりCall Microservice
  getCameraEnduserConfig(idToken: string, confKey: string): Observable<CameraEnduserConfigResponse> {
    // this.logger.debug('cameraEnduserConfig.getCameraEnduserConfig in');

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', idToken);
    const url = nutils.apiUrl(AppDef.MSname.CAMERA_ENDUSER, '/camera-enduser-configs?conf_key=' + confKey);

    // this.logger.debug('getCameraEnduserConfig url ', url);
    return this.http.get<CameraEnduserConfigResponse>(url, { headers: httpHeaders })
    .pipe(
      tap((data) => {
          // this.logger.debug('getCameraEnduserConfig data: ', data);
          return data;
        },
        (error) => {
          // this.logger.warn('getCameraEnduserConfig error: ', error);
          throw nutils.handleErrorThrown(error);
        })
    );
  }

  putCameraEnduserConfig(idToken: string, config: PutCameraEnduserConfigData): Observable<CameraEnduserConfigResponse> {
    // this.logger.debug('cameraEnduserConfig.putCameraEnduserConfig in');

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', idToken);
    const url = nutils.apiUrl(AppDef.MSname.CAMERA_ENDUSER, '/camera-enduser-configs');

    // this.logger.debug('putCameraEnduserConfig url ', url);
    return this.http.put<CameraEnduserConfigResponse>(url, config, { headers: httpHeaders })
    .pipe(
      tap((data) => {
          // this.logger.debug('putCameraEnduserConfig data: ', data);
          return data;
        },
        (error) => {
          // this.logger.warn('putCameraEnduserConfig error: ', error);
          throw nutils.handleErrorThrown(error);
        })
    );
  }

  // ビューア切替表示のON,OFFを管理
  private viewerTabDisplaySb = new Subject<boolean>();

  get viewerTabDisplay$() {
    return this.viewerTabDisplaySb.asObservable();
  }

  updateViewerTabDisplay(b: boolean) {
    this.viewerTabDisplaySb.next(b);
  }

  // コントローラ表示のON,OFFを管理
  private controllerDisplaySb = new Subject<boolean>();

  get controllerDisplay$() {
    return this.controllerDisplaySb.asObservable();
  }

  updateControllerDisplay(b: boolean) {
    this.viewerTabDisplaySb.next(b);
  }

  // 前回表示されていたマルチビューアのパスを管理
  private lastWatchViewerSb = new Subject<string>();

  get lastWatchViewer$() {
    return this.lastWatchViewerSb.asObservable();
  }

  updateLastWatchViewer(str: string) {
    this.lastWatchViewerSb.next(str);
  }

}
