// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: 'maintenance',
  production: false,

  apiUrlBase: 'https://api-mnt.iot-p.neoscorp.ne.jp/c',
  apiUrlBaseAws: '',

  apiLambdaUrlBase: 'https://api-mnt.iot-p.neoscorp.ne.jp/l',

  serverLoggingUrl: 'http://localhost:5081/',

  signupformJsonPath: 'assets/cognito/signupform.json',

  loginLimitCount: 5,
  loginLimitMin: 5,

  log4js: {
    appenders: {
      out: {
        type: 'stdout'
      }
    },
    categories: {
      default: {
        appenders: ['out'],
        level: 'trace'
      }
    }
  },

  i18n: {
    locales: [
      'en',
      'ja'
    ],
    directory: 'locales/'
  },

  //  login_short_url: 'http://localhost:3000/s/client-admin/1',

  /*
  openid_connect: {
    ClientId: '',
    AppWebDomain: 'neos-iot-admin.auth.ap-northeast-1.amazoncognito.com',
    TokenScopesArray: [ 'email', 'openid', 'aws.cognito.signin.user.admin' ],
    RedirectUriSignIn: 'http://localhost:4201/',
    RedirectUriSignOut: 'http://localhost:4201/',
    Storage: sessionStorage
  },
  */
  amplify: {
    Auth: {
      region: 'ap-northeast-1',
      userPoolId: '',
      userPoolWebClientId: '',
      storage: null,
      oauth: {
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin']
      }
    }
  },

  dashboardId: 'a6438680-52c2-46fa-8801-87fc93047418'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
