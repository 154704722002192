import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

import { ROUTES } from './sidebar-routes.config';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from '../animations/custom-animations';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { CameraEnduserConfigService } from '../services/camera-enduser-config.service';
import { ViewerService } from '../services/viewer.service';
import { AuthService } from '../auth/auth.service';
import { PutCameraEnduserConfigData } from '../models/camera-enduser-config';
import { NGXLogger } from 'ngx-logger';
import { ApplicationError } from '../error/application-error';
import { ServiceLabels } from '../models/services';
import { ServicesService } from '../services/services.service';

// import { CAMERA_ENDUSER_SIDEBAR_CONFIGS } from './define';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: customAnimations
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('toggleIcon', { static: false }) toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  private subscriptions = new Subscription();
  viewerId: string;
  serviceName: string;
  serviceLabels: ServiceLabels;

  // サイドメニューのコンフィグ設定
  // sidebarConfigs  = CAMERA_ENDUSER_SIDEBAR_CONFIGS;

  constructor(
    private logger: NGXLogger,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private cameraEnduserConfigService: CameraEnduserConfigService,
    private viewerService: ViewerService,
    private auth: AuthService,
    private servicesService: ServicesService,
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }


  async ngOnInit() {
    this.config = this.configService.templateConf;
    this.menuItems = ROUTES;


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.serviceLabels == undefined) {
      const serviceId = this.auth.getServiceId();
      const serviceLabelsRes = await this.servicesService.getServiceLabels(serviceId).toPromise();
      this.logger.debug("serviceLabelsRes: ")
      this.logger.debug(serviceLabelsRes)
      this.serviceLabels = serviceLabelsRes.service_labels
    }
    if (this.serviceLabels.service_name) {
      this.serviceName = this.serviceLabels.service_name
    } else {
      throw ApplicationError.occurredError('A1042', 'サービス名が取得できませんでした。');
    }
    this.logger.debug("serviceName: " + this.serviceName)
    if (this.serviceLabels.service_logo_url) {
      this.logoUrl = this.serviceLabels.service_logo_url
    } else {
      throw ApplicationError.occurredError('A1043', 'サービスのロゴが取得できませんでした。');
    }
    this.logger.debug("logoUrl: " + this.logoUrl)

    // ユーザコンフィグから最後に見ていたビューアIDを取得
    const idToken = await this.auth.getIdToken();
    this.cameraEnduserConfigService.getCameraEnduserConfig(idToken, 'last_watch_viewer').subscribe((data) => {
      if (data.camera_enduser_configs) {
        if (data.camera_enduser_configs.conf_value !== 'undefined') {
          this.viewerId = data.camera_enduser_configs.conf_value;
        } else {
          this.viewerId = '';
        }
      }
    });

    // 最後に見ていたビューアの保存
    this.subscriptions.add(
      this.cameraEnduserConfigService.lastWatchViewer$.subscribe((str) => {
        if (this.viewerId !== str) {
          const config: PutCameraEnduserConfigData = { conf_key: 'last_watch_viewer', conf_value: str };
          this.cameraEnduserConfigService.putCameraEnduserConfig(idToken, config).subscribe((data) => {
            console.log('putCameraEnduserConfig subscribe');
          });
        }
        this.viewerId = str;
      })
    );

  }

  async goToLastWatchViewer() {
    // ビューアIDが未設定なら１番目のビューアIDを取得
    if (!this.viewerId) {
      const idToken = await this.auth.getIdToken();
      this.viewerService.getViewerList(idToken).subscribe((data) => {
        if (data.viewers && data.viewers.length > 0) {
          this.viewerId = data.viewers[0].viewer_id;
          this.router.navigate(['/viewer/multi', this.viewerId]);
        }
      });
    } else {
      this.router.navigate(['/viewer/multi', this.viewerId]);
    }
  }


  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
        }
      }
    }, 0);


  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1) {
      this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    }
  }

  // スイッチの切り替え
  // onValueChange($event, key: string) {
  //   // コンフィグの定義にkeyが存在する場合は実行
  //   if (key in this.sidebarConfigs) {
  //   // 設定を反転
  //   this.sidebarConfigs[key] = !this.sidebarConfigs[key];
  //   console.log(this.sidebarConfigs);
  //   } else {
  //     console.log('key not found: ' + key);
  //   }
  // }

}
