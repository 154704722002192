<!--Error page starts-->
<section id="error">
  <div class="container-fluid forgot-password-bg">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-sm-12 text-center">
            <img src="../../assets/img/gallery/error.png" alt="" class="img-fluid error-img mt-2" height="300"
              width="400">
            <div class="error-text w-75 mx-auto mt-4">
              <p class="text-white text-left">
                お探しのページは見つかりませんでした。<br />
              </p>
            </div>
            <button class="btn btn-primary btn-lg mt-3">
              <a [routerLink]="['/']" class="text-decoration-none text-white">トップページに戻る</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Error page ends-->
