export interface ErrorOptions {
  serverErrorCode?: string;
  event?: string;
  msg?: string;
  retry?: boolean;
  nextLocation?: string;
  status?: number;
  statusText?: string;
}

export class ApplicationError extends Error {
  //    name: string;    message: string;
  //    stack?: string | undefined;

  //    constructor(catchError: Error);

  //    constructor(message: string, retry: boolean, nextLocationParam: string);

  code = '';
  retry = false;
  nextLocationParam = 'system';
  status: number;
  statusText = '';

  constructor(opts: ErrorOptions) {
    super(opts.msg);

    if (opts.event) {
      // 事象コード 5桁チェック
      if (!opts || opts.event.length !== 5) {
        throw ApplicationError.occurredCodingError('eventはAnnnnの形式、5桁で指定してください。');
      }

      // 1桁目 A のチェック
      if (opts.event.substr(0, 1) !== 'A') {
        throw ApplicationError.occurredCodingError('eventはAnnnnの形式、5桁で指定してください。');
      }

      this.code = opts.event;
    } else if (opts.serverErrorCode) {
      this.code = opts.serverErrorCode;
    } else {
      throw ApplicationError.occurredCodingError('エラーコードを指定してください。');
    }

    if (opts.retry) {
      this.retry = opts.retry;
    }

    if (opts.nextLocation) {
      this.nextLocationParam = opts.nextLocation;
    }

    if (opts.status) {
      this.status = opts.status;
    }

    if (opts.statusText) {
      this.statusText = opts.statusText;
    }
  }

  /*
  constructor(code: string, message: string) {
    super(message);

    this.code = code;
  }
  */

  static occurredError(event: string, message: string): ApplicationError {
    return new ApplicationError({
      event: event,
      msg: message,
    });
  }

  static occurredCanRetryError(event: string, message: string): ApplicationError {
    return new ApplicationError({
      event: event,
      msg: message,
      retry: true,
    });
  }

  static occurredCodingError(message: string): ApplicationError {
    return new ApplicationError({
      event: 'AX001',
      msg: message,
    });
  }

  get canRetry(): boolean {
    if (this.retry === true) {
      return true;
    }

    return false;
  }

  get nextLocation(): string | null {
    if (this.nextLocationParam === null) {
      return null;
    }

    if (this.nextLocationParam.length > 0) {
      if (this.nextLocationParam === 'system') {
        return '/error/system';
      }

      return this.nextLocationParam;
    }

    return null;
  }
}
