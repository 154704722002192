import { Injectable } from '@angular/core';
import { ApplicationError } from './application-error';

@Injectable({
  providedIn: 'root'
})
export class AppErrorDataService {

  constructor() {
  }

  appErr: ApplicationError;

  updateError(appErr: ApplicationError) {
    this.appErr = appErr;
  }
}
