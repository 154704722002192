import { AfterViewInit, Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

import * as _ from 'lodash';
import { ApplicationError } from '../error/application-error';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { NavbarService } from '../services/navbar.service';
import { MULTI_VIEWER_PATH } from './define';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [
    './navbar.component.scss',
  ]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  public isCollapsed = true;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};
  private subscriptions = new Subscription();
  title = '';
  isMultiViewer = false;

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private router: Router,
    private auth: AuthService,
    // スピナー
    private spinner: NgxSpinnerService,
    private navbarService: NavbarService,
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    // const pageTitleList = PAGE_TITLE_LIST;
    // const pageTitlePathList = PAGE_TITLE_PATH_LIST;

    // タイトルを受け取る
    this.subscriptions.add(
      this.navbarService.title$.subscribe((title: string) => {
        this.title = title;
        const url = this.router.url;
        const pattern = MULTI_VIEWER_PATH;
        if (url.indexOf(pattern) === 0) {
          this.isMultiViewer = true;
        } else {
          this.isMultiViewer = false;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      const dir = this.config.layout.dir;
      if (dir === 'rtl') {
        this.placement = 'bottom-left';
      } else if (dir === 'ltr') {
        this.placement = 'bottom-right';
      }
    }
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  async onClickLogout() {
    this.spinner.show();

    const serviceId = await this.auth.getAttribute('custom:service_id');
    if (_.isString(serviceId)) {
      await this.auth.signOut(serviceId as string);
      this.router.navigate([`/auth/login/${serviceId}`]);

      this.spinner.hide();
    } else {
      this.spinner.hide();
      throw ApplicationError.occurredError('A1023', 'cannot read serviceId');
    }
  }

  // ビューア設定のリンク
  settingViewer() {
    this.router.navigate(['/viewer-setting/viewer-list']);
  }
}
