import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'camera-list',
    loadChildren: () => import('../../camera-list/camera-list.module').then(m => m.CameraListModule)
  },
  {
    path: 'movie-clips',
    loadChildren: () => import('../../movie-clips/movie-clips.module').then(m => m.MovieClipsModule)
  },
  {
    path: 'viewer-setting',
    loadChildren: () => import('../../viewer-setting/viewer-setting.module').then(m => m.ViewerSettingModule)
  },
  {
    path: 'viewer',
    loadChildren: () => import('../../viewer/viewer.module').then(m => m.ViewerModule)
  },
  {
    path: 'manual',
    loadChildren: () => import('../../manual/manual.module').then(m => m.ManualModule)
  },
  /*
  {
    path: 'error',
    loadChildren: () => import('../../error/error.module').then(m => m.ErrorModule)
  },
  */

  {
    path: 'full-layout',
    loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
  },

];
