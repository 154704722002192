import { Directive, HostListener } from '@angular/core';

import screenfull, { Screenfull } from 'screenfull';

@Directive({
  selector: '[appToggleFullscreen]'
})
export class ToggleFullscreenDirective {

  @HostListener('click') onClick() {
    if ((screenfull as Screenfull).enabled) {
      (screenfull as Screenfull).toggle();
    }
  }
}
