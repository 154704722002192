import { Routes } from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_NOAUTH_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'error',
    loadChildren: () => import('../../error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
  },
];
