import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as nutils from 'app/nutils';
import * as AppDef from 'app/app-define';
//import * as AppDef from 'app/app-define';
import { ServiceLabels, ServiceLabelsResponse } from '../models/services';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  private serviceLabels = new BehaviorSubject<ServiceLabels>(undefined);

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
  ) {
  }

  getServiceLabelsValue() {
    return this.serviceLabels.getValue();
  }

  getServiceLabels(serviceId: string): Observable<ServiceLabelsResponse> {

    const url = nutils.apiUrl(AppDef.MSname.SERVICE, `/service/labels/${serviceId}`);

    this.logger.debug('service/labels', url);
    return this.http.get<ServiceLabelsResponse>(url)
      .pipe(
        tap((data) => {
          this.logger.debug('service/labels: ', data);
          this.serviceLabels.next(data.service_labels);
          return data;
        },
          (error) => {
            this.logger.warn('service/labels error: ', error);
            throw nutils.handleErrorThrown(error);
          })
      );
  }
}
