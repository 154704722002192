import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    // 共通ロガー
    private logger: NGXLogger,
    // メッセージ管理
    private msgadm: TranslateService,
    // トースターメッセージ表示
    private toastr: ToastrService,
    // ルーター。
    private router: Router,
    private authService: AuthService,
  ) {
  }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.logger.info('AuthGuard.canActivate start');

    // 権限
    const url: string = state.url;
    return this.checkLogin(url);
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.logger.info('AuthGuard.canActivateChild start');

    return this.canActivate(route, state);
  }

  async checkLogin(url: string): Promise<boolean> {
    const authF = await this.authService.isAuthenticated();
    if (authF === true) {
      return true;
    }

    // トースト表示
    this.toastr.warning(this.msgadm.instant('NOT-AUTHORIZED-ERROR'));

    // ローカルストレージからサービスIDを取得
    const serviceId = localStorage.getItem('serviceId');
    if (serviceId === null) {
      this.router.navigate(['/error/system']);
    } else {
      this.router.navigate([`/auth/login/${serviceId}`]);
    }
  }
}
