import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard/human', title: 'ダッシュボード(人検知)', icon: 'icon-bar-chart',
    class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/dashboard/dog', title: 'ダッシュボード(犬)', icon: 'icon-bar-chart',
    class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/viewer/multi', title: 'カメラビューア', icon: 'icon-screen-desktop',
    class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/viewer-setting/viewer-list', title: 'ビューア設定', icon: 'icon-settings',
    class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/camera-list/card', title: 'カメラ一覧', icon: 'icon-camera',
    class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/movie-clips/card', title: 'ムービークリップ', icon: 'icon-film',
    class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/manual/device-init-download', title: 'デバイス初期化アプリ', icon: 'icon-cloud-download',
    class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: 'javascript:;', title: '契約情報', icon: 'icon-credit-card',
  //   class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //   path: 'javascript:;', title: 'ユーザ設定', icon: 'icon-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //   path: 'javascript:;', title: 'サポート', icon: 'icon-question', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/auth/demo-init', title: '開発デモ', icon: 'icon-wrench',
  //   class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },

  // {
  //   path: '/rd-clip-ui', title: '(仮)クリップUI', icon: 'icon-support',
  //   class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/movie', title: '(仮)クリップ作成', icon: 'icon-support',
  //   class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },

  // 下記の１ブロックはサブメニューにスイッチのボタンを実装した時のコード
  // {
  //     path: '/viewer/camera', title: 'カメラビューア', icon: 'icon-screen-desktop',
  //     class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  //         {
  //             path: '/viewer/camera', title: 'カメラビューア', icon: '',
  //             class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [], isSwitch: false, switchKey: ''
  //         },
  //         {
  //             path: '/viewer-setting/viewer-list', title: 'ビューア設定', icon: '',
  //             class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [], isSwitch: false, switchKey: ''
  //         },
  //         {
  //             path: '', title: 'ビューア切り替え表示', icon: '',
  //             class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], isSwitch: true, switchKey: 'viewer_change_display'
  //         },
  //         {
  //             path: '', title: 'コントロール表示', icon: '',
  //             class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], isSwitch: true, switchKey: 'control_display'
  //         },
  //     ], isSwitch: false, switchKey: ''
  // },
  // {
  //     path: '/full-layout', title: 'Full Layout', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //     path: '/content-layout', title: 'Content Layout', icon: 'ft-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //     path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '1', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
  //     submenu: [
  //         { path: 'javascript:;', title: 'Second Level', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
  //         {
  //             path: '', title: 'Second Level Child', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //             submenu: [
  //                 { path: 'javascript:;', title: 'Third Level 1.1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []  },
  //                 { path: 'javascript:;', title: 'Third Level 1.2', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []  },
  //             ]
  //         },
  //     ]
  // },
  //     {
  //         path: '/changelog', title: 'ChangeLog', icon: 'ft-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //     { path: 'https://pixinvent.com/apex-angular-4-bootstrap-admin-template/documentation', title: 'Documentation', icon: 'ft-folder', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
  //     { path: 'https://pixinvent.ticksy.com/', title: 'Support', icon: 'ft-life-buoy', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },


  /*
  {
      path: '/full-layout', title: 'Full Layout', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
      path: '/content-layout', title: 'Content Layout', icon: 'ft-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
      path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '1', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
      submenu: [
          { path: 'javascript:;', title: 'Second Level', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
          {
              path: '', title: 'Second Level Child', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
              submenu: [
                  { path: 'javascript:;', title: 'Third Level 1.1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
                  { path: 'javascript:;', title: 'Third Level 1.2', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
              ]
          },
      ]
  },
  {
      path: '/changelog', title: 'ChangeLog', icon: 'ft-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  { path: 'https://pixinvent.com/apex-angular-4-bootstrap-admin-template/documentation', title: 'Documentation', icon: 'ft-folder', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
  { path: 'https://pixinvent.ticksy.com/', title: 'Support', icon: 'ft-life-buoy', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
  */

];
