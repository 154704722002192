import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';

@Injectable()
export class DialogService {
  private readonly defaultModalOptions = {
    size: 'sm',
    centered: true,
  };

  constructor(private modalService: NgbModal) {
    fromEvent(window, 'popstate').subscribe(() => {
      modalService.dismissAll();
    });
  }

  /**
   * ダイアログを開く。
   * @param component ダイアログとして開きたいコンポーネント
   * @param params ダイアログコンポーネントに渡す値をまとめたオブジェクト
   * @param modalOptions モーダルダイアログのオプション情報
   * @param dismissValue ダイアログの外側をクリックするなどして閉じた場合の戻り値
   */
  open(component: any, params: object = {}, modalOptions?: NgbModalOptions, dismissValue?: any): Promise<any> {
    const options = Object.assign(this.defaultModalOptions, modalOptions);
    const modalRef = this.modalService.open(component, options);
    Object.keys(params).forEach(key => {
      modalRef.componentInstance[key] = params[key];
    });

    return modalRef.result.then(val => val, () => dismissValue);
  }
}
